import React from "react";
import OurTeamHeader from "./OurTeamHeader";
import AshishKumar from "./Assets/Images/employees-final/ashish.jpg";
import AbdulAfjal from "./Assets/Images/employees-final/abdul.jpg";
import ArunPrabhu from "./Assets/Images/employees-final/arun.jpg";
// import Ayyub from './Assets/Images/employees-final/ayyub.jpg'
import MdIrfanAli from "./Assets/Images/employees-final/irfan.jpg";
import KavyaBS from "./Assets/Images/employees-final/kavya.jpg";
// import NehaTyagi from './Assets/Images/employees-final/neha.jpg'
import Pinky from "./Assets/Images/employees-final/pinky.jpg";
// import RaviPrajapati from './Assets/Images/employees-final/ravi.jpg'
import ReetaYadav from "./Assets/Images/employees-final/reeta.jpg";
import RohitKumar from "./Assets/Images/employees-final/rohit.jpg";
import ShashankShekhar from "./Assets/Images/employees-final/shashank.jpg";
// import Shivam from './Assets/Images/employees-final/shivam.jpg'
import MukhtarAhmad from "./Assets/Images/employees-final/mukhtar.jpg";
import PreetiDevi from "./Assets/Images/employees-final/preeti.jpg";
import ShivamKumar from "./Assets/Images/employees-final/shivam-kumar.jpg";
import muskan from './Assets/Images/employees-final/muskan.jpeg'
import prachi from './Assets/Images/employees-final/prachi.jpeg'
// import VijayRajput from './Assets/Images/employees-final/vijay.jpg'
import Revathi from "./Assets/Images/employees-final/revathi.jpg";
import AninditaGhosh from "./Assets/Images/employees-final/anindita.jpg";
// import DeepikaSethi from './Assets/Images/employees-final/deepika.jpg'
import SoniPrabhu from "./Assets/Images/employees-final/soni.jpg";
import AnkitJha from "./Assets/Images/employees-final/ankit.jpeg";
// import Anushka_singh from './Assets/Images/employees-final/Anushka_singh.jpeg'
// import MonikaRajput from './Assets/Images/employees-final/monika-rajput.jpg'
// import hasan from './Assets/Images/employees-final/shaeba-hasan.png'
import SVenugopal from "./Assets/Images/employees-final/s-venugopal.png";
import uzma from "./Assets/Images/employees-final/uzma.jpeg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
function OurTeam() {
  return (
    <div>
      <OurTeamHeader />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center my-5">
            <h1 className="display-6">Meet Our Team</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-2 text-center">
        <div className="row">
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={AshishKumar}
                  alt="Ashish Kumar"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Ashish Kumar
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    CEO & Director
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={ArunPrabhu}
                  alt="Arun Prabhu"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Arun Prabhu
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Vice President
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={MukhtarAhmad}
                  alt="Mukhtar Ahmad"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Mukhtar Ahmad
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Operations Manager
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={AninditaGhosh}
                  alt="Anindita Ghosh"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Anindita Ghosh
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Sr. Manager Strategy (Sales & Operations)
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          {/* <div className='col-md-3 mb-2'>
              <Card sx={{ maxWidth: 300 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="300"
                    image={DeepikaSethi}
                    alt="Deepika Sethi"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Deepika Sethi
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Sales & Project Manager
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div> */}
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={SoniPrabhu}
                  alt="Sunita Prabhu"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Sunita Prabhu
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Project Manager
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={Pinky}
                  alt="Pinky Chowdhury"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Pinky Chowdhury
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Talent Acquisition Manager
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={ShashankShekhar}
                  alt="Shashank Shekhar"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Shashank Shekhar
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Senior Graphics Designer
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          {/* <div className='col-md-3 mb-2'>
              <Card sx={{ maxWidth: 300 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="300"
                    image={NehaTyagi}
                    alt="Neha Tyagi"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Neha Tyagi
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Manager - HR Admin
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div> */}
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={MdIrfanAli}
                  alt="Md. Irfan Ali"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Md. Irfan Ali
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Operations Executive
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={ShivamKumar}
                  alt="Shivam Kumar"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Shivam Kumar
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Graphics Designer
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={ReetaYadav}
                  alt="Reeta Kumari"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Reeta Kumari
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Operations Executive
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          {/* <div className='col-md-3 mb-2'>
              <Card sx={{ maxWidth: 300 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="300"
                    image={RaviPrajapati}
                    alt="Ravi Prajapati"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Ravi Prajapati
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Operations Executive
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <div className='col-md-3 mb-2'>
              <Card sx={{ maxWidth: 300 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="300"
                    image={VijayRajput}
                    alt="Vijay Rajput"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Vijay Rajput
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Admin Executive
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div> */}

          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={AbdulAfjal}
                  alt="Abdul Afjal"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Abdul Afjal
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Full Stack Developer (React/Phyton,Django)
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>

          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={KavyaBS}
                  alt="Kavya BS"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Kavya BS
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    HR Executive
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>

          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={RohitKumar}
                  alt="Rohit Kumar"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Rohit Kumar
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    MIS Executive
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={PreetiDevi}
                  alt="Preeti Devi"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Preeti Devi
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    HR Executive
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>

          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={Revathi}
                  alt="Revathi"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Revathi
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Operations Executive
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={AnkitJha}
                  alt="Ankit Jha"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Ankit Kumar Jha
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Frontent Developer
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>

          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={SVenugopal}
                  alt="S Venugopal"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    S Venugopal
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Verification Executive
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className='col-md-3 mb-2'>
              <Card sx={{ maxWidth: 300 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="300"
                    image={muskan}
                    alt="muskan"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                   Muskan Lakra
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                     MIS Intern
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  // image={hasan}
                  image={uzma}
                  alt="uzma"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                  Uzma Shabir
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  MIS Executive
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="col-md-3 mb-2">
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  // image={hasan}
                  image={prachi}
                  alt="prachi"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                  Prachi Bharti 
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  Operation Executive
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurTeam;
